
import { getCompanyMembersQuery } from "~/helpers/apollo/apollo-company-query-helpers.js";
import PersonLink from "~/components/EntityLink/components/PersonLink.vue";

export default {
  name: "Stakeholders",

  components: {
    PersonLink,
  },

  inheritAttrs: false,

  apollo: {
    members: getCompanyMembersQuery,
  },

  computed: {
    loading: function () {
      return this.$apollo.queries.members.loading;
    },

    membersAndAttributes: function () {
      if (!this.members) {
        return [];
      }

      let results = [];

      for (let i = 0; i < this.members.length; i++) {
        const person = this.members[i];
        // remapping all nested attributes to single array

        const attributes = person.organizations.flatMap((org) => {
          return org.attributes;
        });

        // getting all company-role related attributes
        const activeRoleAttributes = attributes
          .filter((attr) => attr && attr.type.toLowerCase() == "funktion" && !attr.validTo)
          .map((attr) => {
            return {
              role: attr.value,
              validTo: attr.validTo,
              validFrom: attr.validFrom,
            };
          });

        if (activeRoleAttributes.length == 0) {
          continue;
        }

        // getting all remaining attributes for later usage
        const otherAttributes = attributes.filter((attr) => attr && attr.type.toLowerCase() != "funktion" && attr.type.toLowerCase() != "valgform");

        // getting share percentage. only 1 can exist.
        const share = attributes.filter((attr) => attr && attr.type.toLowerCase() == "ejerandel_procent")[0];

        // adding all active people to output array. Structure is like this, after talking with mikkel
        // because we are later going to display all roles on a single list item.
        // for now, we create a list item for each role in the company a person has
        // After talk with ida, we only show share on the "real owners"
        results.push({
          ...person,
          activeRoles: activeRoleAttributes,
          otherAttributes,
          share: share && activeRoleAttributes.filter((a) => a.role === "Reel ejer").length > 0 ? share.value : null,
          cvr: person.entityType === "VIRKSOMHED" ? person.entityKey : null,
        });
      }

      // we sort those to the top as temp fix
      return results.sort((a, b) => {
        const a1 = a.activeRoles[0].role == "Reel ejer";
        const b1 = b.activeRoles[0].role == "Reel ejer";

        if (a1 && !b1) {
          return -1;
        }

        if (b1 && !a1) {
          return 1;
        }

        return 0;
      });
    },
  },

  methods: {
    getRole: function (attr) {
      switch (attr.toLowerCase()) {
        case "formand":
          return this.$t("OWNERS_C_CHAIR");
        case "adm. dir":
          return this.$t("OWNERS_C_CEO");
        case "adm. dir.":
          return this.$t("OWNERS_C_CEO");
        case "næstformand":
          return this.$t("OWNERS_C_VICE");
        case "bestyrelsesmedlem":
          return this.$t("OWNERS_C_BOARD");
        case "bestyrelse":
          return this.$t("OWNERS_C_BOARD");
        case "suppleant":
          return this.$t("OWNERS_C_SUP");
        case "stiftere":
          return this.$t("OWNERS_C_FOUNDER");
        case "revision":
          return this.$t("OWNERS_C_AUD");
        case "bæredygtighedsrevision":
          return this.$t("OWNERS_C_ENV_AUD");
        case "direktør":
          return this.$t("OWNERS_C_MANAGER");
        case "reel ejer":
          return this.$t("OWNERS_C_BEN");
        case "direktion":
          return "Director";
        default:
          return this.$t("COMPANY_TRANSACTIONS_UNKNOWN");
      }
    },
  },
};
